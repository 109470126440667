export default [
  {
    title: "Dashboard",
    route: "home",
    icon: "HomeIcon",
    action: "read",
    resource: "home",
  },
  {
    title: "Masters",
    route: "countrymaster",
    icon: "AwardIcon",
    resource: "countrymaster",
    children: [
      {
        title: "Country",
        route: "countrymaster",
        action: "read",
        resource: "countrymaster",
      },
      {
        title: "City",
        route: "citymaster",
        action: "read",
        resource: "citymaster",
      },
      {
        title: "Currency",
        route: "currencymaster",
        action: "read",
        resource: "currencymaster",
      },
      {
        title: "Cinema/Theatre",
        route: "cinemamaster",
        action: "read",
        resource: "cinemamaster",
      },
      {
        title: "Genre",
        route: "genremaster",
        action: "read",
        resource: "genremaster",
      },
      {
        title: "Language",
        route: "languagemaster",
        action: "read",
        resource: "languagemaster",
      },
      {
        title: "Seat Types",
        route: "seattypemaster",
        action: "read",
        resource: "seattypemaster",
      },
    ],
  },
  {
    title: "Event",
    route: "eventlist",
    icon: "FilmIcon",
    resource: "eventlist",
    children: [
      {
        title: "Event List",
        route: "eventlist",
        action: "read",
        resource: "eventlist",
      },

      {
        title: "Event Banners",
        route: "eventbanners",
        action: "read",
        resource: "eventbanners",
      },
    ],
  },
  {
    title: "Movie",
    route: "movielist",
    icon: "VideoIcon",
    resource: "movielist",
    children: [
      {
        title: "Movie List",
        route: "movielist",
        action: "read",
        resource: "movielist",
      },

      // {
      //   title: "Movie Banners",
      //   route: "moviebanners",
      //   action: "read",
      //   resource: "moviebanners",
      // },
    ],
  },

  {
    title: "Event/Movie Pass",
    route: "passlist",
    icon: "GiftIcon",
    action: "read",
    resource: "voucherlist",
  },
  {
    title: "Seat Layout",
    route: "eventseatlayout",
    icon: "CircleIcon",
    action: "read",
    resource: "eventseatlayout",
  },
  {
    title: "Reports",
    route: "transactionreport",
    icon: "ActivityIcon",

    resource: "transactionreport",
    children: [
      {
        title: "Booking Reports",
        route: "transactionreport",
        action: "read",
        resource: "transactionreport",
      },
      {
        title: "Reservation Reports",
        route: "reservationreport",
        action: "read",
        resource: "reservationreport",
      },
    ],
  },
  {
    title: "Vouchers",
    route: "voucherlist",
    icon: "GiftIcon",
    action: "read",
    resource: "voucherlist",
  },
  {
    title: "Contact Us & Subscribers ",
    route: "contactuslist",
    icon: "MessageSquareIcon",
    action: "read",
    resource: "contactuslist",
  },

  {
    title: "Users & Roles",
    route: "userlist",
    icon: "UsersIcon",
    resource: "userlist",
    children: [
      {
        title: "Users ",
        route: "userlist",
        action: "read",
        resource: "userlist",
      },
      {
        title: "Roles",
        route: "roleList",
        action: "read",
        resource: "roleList",
      },
    ],
  },
  {
    title: "Organization",
    route: "organizationlist",
    icon: "GlobeIcon",
    action: "read",
    resource: "organizationlist",
  },
];
